<template>
  <div class="product-page--create">
    <v-row v-if="campaignDefault">
      <v-col cols="12">
        <redirect class="mb-5" title="Editor" route="website.apps.print-hub.campaigns" />
        <div class="page-header">
          <h2 class="page-title">{{ campaignDefault.title }}</h2>
        </div>
      </v-col>

      <v-col cols="12" sm="7" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <InputDefault
            :min="0"
            :max="255"
            :model="['campaignDetail', 'title']"
            fieldName="Title"
            label="Title"
            :isValidate="true"
          />
          <editor-new :height="500" :dataT.sync="campaignDetail.description" :title="`Description`" />

          <!-- <ContentInput :model="`bodyHtml`" :height="400" title="Description" subtitle="Maximum 100.000 characters" /> -->
        </div>
        <div class="mt-3">
          <InputDefault
            :min="0"
            :max="255"
            :model="['campaignDetail', 'productType']"
            fieldName="productType"
            label="Product Type"
            :isValidate="false"
          />
        </div>
      </v-col>
      <v-col cols="12" sm="5" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <label
            style="    font-weight: 700;
    font-size: 14px;
    color: #55586c;"
            for=""
            >Image</label
          >
          <v-col cols="12">
            <preview-campaign :colorDefault="defaultColor" />
          </v-col>

          <div>
            <div>
              Select main color
            </div>
            <div
              role="tabpanel"
              aria-labelledby="s-collapse-head-4823"
              id="s-collapse-content-4823"
              class="s-collapse-item__wrap"
            >
              <div class="s-collapse-item__content">
                <div v-if="listColorSelect" style="display: flex;flex-wrap: wrap;" class="color-section">
                  <div
                    v-for="i in listColorSelect.values"
                    :key="i.code"
                    class="s-tooltip-fixed item-color mt-3 ma-1"
                    animated=""
                    @click="setColor(i)"
                    :style="defaultColor === i.code ? 'border: 1px solid #151212dd;' : ''"
                  >
                    <div class="color cursor-pointer" :style="`background-color: ${i.code}`">
                      <div class="color__active">
                        <span class="s-icon h-100p is-small" style="color: rgb(255, 255, 255);"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <label class="fz-12">Tags</label>
          <v-combobox
            v-model.trim="campaignDetail.tags"
            hide-selected
            multiple
            persistent-hint
            small-chips
            placeholder="Enter"
            hide-details
            append-icon=""
            :delimiters="[',']"
          >
            <template v-slot:selection="data">
              <v-chip
                close
                small
                label
                :key="data.item"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
              >
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
          <small>Add this product to a collection so it’s easy to find in your store.</small>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <div
            style="    font-weight: 700;
    font-size: 14px;
    color: #55586c;"
            class="my-3"
          >
            <!-- Set price , description with size -->
          </div>
          <v-row class="align-center">
            <v-col cols="1">
              <h4>{{ `Size` }}</h4>
            </v-col>
            <v-col cols="2">
              <h4>{{ `Price` }}</h4>
            </v-col>
            <v-col cols="2">
              <h4>{{ `Compare At Price` }}</h4>
            </v-col>
            <v-col cols="7">
              <h4>{{ `Description` }}</h4>
            </v-col>
            <!-- <v-col cols="12">Enter value to input then click outside </v-col> -->
          </v-row>
          <v-col cols="12">
            <v-divider class=""></v-divider>
          </v-col>

          <div v-for="i in sizeVariantAttributesDefault" :key="i.code">
            <v-row class="align-center">
              <v-col cols="1">
                <h3 style="    margin-top: -15px;">{{ i.code }}</h3>
              </v-col>
              <v-col cols="2">
                <v-text-field @input="loadData" type="number" v-model.number="i.price"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field @input="loadData" type="number" v-model.number="i.compareAtPrice"></v-text-field>
              </v-col>
              <v-col cols="7">
                <editor-new @input="loadData" :dataT.sync="i.description" :title="``" />
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <div
            style="    font-weight: 700;
    font-size: 14px;
    color: #55586c;"
            class="my-3"
          >
            Set individual price or all
          </div>
          <div>
            <table id="table-edit-variant">
              <tr>
                <th style="width:250px">Unisex T-shirt</th>
                <th>SALE PRICE</th>
                <th>COMPARE AT PRICE</th>
              </tr>
              <tr v>
                <td>
                  <div>
                    <!-- {{ campaignDefault.mockup }} -->
                    <p>{{ campaignDefault.mockup.title }}</p>
                    <a class="text-small">
                      <span v-if="!isShowMore" @click="isShowMore = true" class="pointer">
                        <i class="mdi mdi-chevron-double-down"></i>
                        Set individual price
                      </span>
                      <span v-else @click="isShowMore = false" class="pointer">
                        <i class="mdi mdi-chevron-double-up"></i>
                        See less
                      </span>
                    </a>
                  </div>
                </td>
                <td>
                  <v-text-field
                    @change="changeAll(`salePrice`)"
                    v-model.number="campaignDetail.mockup.attributes.salePrice"
                    type="number"
                    hide-details=""
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    @change="changeAll(`compareAtPrice`)"
                    v-model.number="campaignDetail.mockup.attributes.compareAtPrice"
                    type="number"
                    hide-details=""
                  ></v-text-field>
                </td>
              </tr>
              <template v-if="isShowMore">
                <tr v-for="(i, k) in campaignDetail.variants" :key="i.title + k">
                  <td>{{ i.title }}</td>
                  <td>
                    <v-text-field v-model="i.price" hide-details=""></v-text-field>
                  </td>
                  <td><v-text-field v-model="i.compareAtPrice" hide-details=""></v-text-field></td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-btn @click="showDialogDelete = true" color="error" elevation="">Delete</v-btn>

        <v-spacer></v-spacer>
        <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
        <v-btn :loading="isLoading" :disabled="isLoading" color="primary" @click="onSubmit">Save</v-btn>
      </v-col>
    </v-row>

    <div style="width: 100%; height: 100%" v-else class="d-flex justify-center align-center">
      <page-loader />
    </div>

    <delete-action :content="'product ' + name" :title="'Delete ' + name" />
  </div>
</template>

<script>
import ContentInput from '@/views/website/components/Editor';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import { printHubApi } from '@/apis/printHub';
import EditorNew from '../../../themes/components/EditorNew.vue';
import imageHelpers from '@/helpers/image';
import PreviewCampaign from './PreviewCampaign.vue';

export default {
  validations: {
    campaignDetail: {
      title: {
        required,
        minLength: minLength(0),
        maxLength: maxLength(255),
      },
    },
  },
  components: {
    EditorNew,
    PreviewCampaign,
  },
  watch: {
    sizeVariantAttributesDefault: {
      handler: function name(params) {
        console.log(`change`);
      },
      immediate: true,
    },
  },
  data() {
    return {
      executor: null,
      image: null,
      imageHelpers,
      listColorSelect: null,
      defaultColor: null,
      isLoading: false,
      showDialogDelete: false,
      name: `name`,
      isShowMore: false,
      sizeVariantAttributesDefault: {},
      sizeVariants: [],
      keyUpAt: null,
      stageSize: {
        width: 195,
        height: 255,
      },
      campaignDetail: {
        title: '',
        bodyHtml: ``,
        productType: ``,
      },
      campaignDefault: null,
    };
  },
  async created() {
    await this.getData();
  },
  mounted() {},
  methods: {
    loadData() {
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }

      this.executor = setTimeout(() => {
        this.genVariant();
      }, 300);
    },
    test() {
      let box = document.querySelector('#editorV');
      console.log(`box`, box);
      const observer = new ResizeObserver(() => {
        this.stageSize.width = box.offsetWidth;
        this.stageSize.height = box.offsetHeight;
      });
      observer.observe(box);
    },
    styled(element) {
      // console.log(`this.campaignDefault`, this.campaignDefault?.layouts[0].slot);
      let { top, left, width, height } = this.campaignDefault.mockup.layouts[0].slot;
      console.log(`top, left, width, height`, element);
      if (element === 'editor') {
        return `top:${top}%;left: ${left}%; width: ${width}%; height: ${height}%;`;
      }

      if (element === `konva-wrapper`) {
        return `width: calc(${width}% + 0px); height: calc(${height}% + 2px); top: calc(${top}% - 1px); left: calc(${left}% - 1px);`;
      }
      let box = document.querySelector('#editorV');
    },
    setColor(i) {
      this.defaultColor = i.code;
      if (this.listColorSelect.values[0].code !== this.defaultColor) {
        let indexColor = this.listColorSelect.values.findIndex(it => it.code === this.defaultColor);
        let colors = this.listColorSelect.values;
        var b = colors[0];
        colors[0] = colors[indexColor];
        colors[indexColor] = b;
        this.campaignDetail.variants = [];
        this.campaignDefault.options[0] = this.listColorSelect;
        this.genVariant(
          this.campaignDefault.mockup.attributes.salePrice,
          this.campaignDefault.mockup.attributes.compareAtPrice,
        );
      }
    },
    changeAll() {
      // this.genVariant(
      //   this.campaignDetail.mockup.attributes.salePrice,
      //   this.campaignDetail.mockup.attributes.compareAtPrice,
      // );
      for (let i = 0; i < this.campaignDetail.options[0].values.length; i++) {
        for (let j = 0; j < this.campaignDetail.options[1].values.length; j++) {
          this.campaignDetail.variants = this.campaignDetail.variants.map(it => {
            return {
              ...it,
              price: parseFloat(this.campaignDetail.mockup.attributes.salePrice) || 0,
              compareAtPrice: parseFloat(this.campaignDetail.mockup.attributes.compareAtPrice) || 0,
              descriptionOfVariant: this.sizeVariantAttributesDefault[this.campaignDetail.options[1].values[j].code]
                .description,
            };
          });
        }
      }
    },
    genVariant(price, compareAtPrice) {
      this.campaignDetail.variants = [];
      console.log(`this.sizeVariantAttributesDefault`, this.sizeVariantAttributesDefault);
      try {
        for (let i = 0; i < this.campaignDetail.options[0].values.length; i++) {
          for (let j = 0; j < this.campaignDetail.options[1].values.length; j++) {
            this.campaignDetail.variants.push({
              option1: `${this.campaignDetail.options[0].values[i].value}`,
              option2: `${this.campaignDetail.options[1].values[j].value}`,
              active: true,
              sku: 'SKU ',
              barCode: 'Barcode ',
              cost: 0,
              price:
                parseFloat(this.sizeVariantAttributesDefault[this.campaignDetail.options[1].values[j].code].price) ||
                parseFloat(price) ||
                0,
              compareAtPrice:
                parseFloat(
                  this.sizeVariantAttributesDefault[this.campaignDetail.options[1].values[j].code].compareAtPrice,
                ) ||
                parseFloat(compareAtPrice) ||
                0,
              descriptionOfVariant: this.sizeVariantAttributesDefault[this.campaignDetail.options[1].values[j].code]
                .description,
              inventoryPolicy: 'deny',
              requiresShipping: true,
              weightUnit: 'lb',
              weight: 0,
              inventoryQuantity: null,
              title: `${this.campaignDetail.options[0].values[i].value}/${this.campaignDetail.options[1].values[j].value}`,
            });
          }
        }
      } catch (error) {
        console.log(error, 'error');
      }
    },
    arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      console.log('::' + arr);
      arr.splice(toIndex, 0, element);
    },
    async getData() {
      try {
        this.isLoading = true;
        let res = await printHubApi.getCampaignById(this.$route.params.id);
        this.campaignDetail = Object.assign({}, res.data.campaign);
        this.campaignDetail.description = res.data.campaign?.mockup?.description || '';
        this.campaignDefault = Object.assign({}, res.data.campaign);
        await this.getSize(this.campaignDefault.mockup.sizeOption);
        this.genVariant(
          this.campaignDefault.mockup.attributes.salePrice,
          this.campaignDefault.mockup.attributes.compareAtPrice,
        );
        this.listColorSelect = this.campaignDefault.options.find(it => it.name === 'Color'); //hardcode key Option Color
        this.defaultColor = this.listColorSelect.values[0].code;
        if (this.campaignDefault.status === 2 || this.campaignDefault.status === 3) return;
      } catch (error) {}
      this.isLoading = false;
    },
    async onSubmit() {
      try {
        this.$v.$touch();
        this.isLoading = true;
        // console.log(' this.campaignDetail', this.campaignDetail, this.campaignDetail.options[1]);
        // delete this.campaignDetail.mockup;
        // this.campaignDetail.mockup = this.campaignDefault.mockup._id;

        let convert = Object.assign({}, this.campaignDetail);
        delete convert._id;
        delete convert.mockup;
        delete convert.status;
        delete convert.designs;
        delete convert.product;
        // convert.mockup = this.campaignDetail.mockup._id;

        convert.variants = this.campaignDetail.variants.map(it => {
          return {
            ...it,
            price: parseFloat(it.price),
            compareAtPrice: parseFloat(it.compareAtPrice),
          };
        });
        console.log(convert, 'convert');
        printHubApi.updateCampaign(this.$route.params.id, {
          campaign: convert,
          launch: 1,
        });
        this.$router.push({ name: 'website.apps.print-hub.campaigns' });

        this.isLoading = false;
      } catch (error) {
        console.log(`error`, error);
      }
    },
    async getSize(id) {
      try {
        if (id) {
          let res = await printHubApi.getOption(id);
          this.sizeVariants = res.data.option.values;
          for (let i = 0; i < res.data.option.values.length; i++) {
            let element = res.data.option.values[i];
            this.sizeVariantAttributesDefault[element.code] = {
              ...element,
              price: element.price !== 0 ? element.price : this.campaignDefault.mockup.attributes.salePrice,
              compareAtPrice:
                element.compareAtPrice !== 0
                  ? element.compareAtPrice
                  : this.campaignDefault.mockup.attributes.compareAtPrice,
            };
          }
        }
        console.log(`this.sizeVariantAttributesDefault`, this.sizeVariantAttributesDefault);
      } catch (error) {}
    },
    onCancel() {},
    // onSubmit() {},
  },
};
</script>
<style lang="scss" scoped>
.editor__container {
  max-height: 766px;
  height: calc(100% - 112px);
  display: inline-block;
  position: relative;
}
.konva-wrapper {
  // width: calc(53.4382% + 2px);
  // height: calc(48.8578% + 2px);
  // top: calc(15.6503% - 1px);
  // left: calc(22.3348% - 1px);
  position: absolute;
  // border: 1px dashed rgba(26, 31, 35, 0.235);
}
#editorV {
  // top: 15.6503%;
  // left: 22.3348%;
  // width: 53.4382%;
  // height: 48.8578%;
  position: absolute;
  div:first-of-type {
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss" scoped>
.item-color {
  cursor: pointer;
  .color {
    margin-top: px;
    position: relative;
    content: '';
    width: 32px;
    height: 32px;
    background-color: hsla(0, 0%, 100%, 0);
    border-radius: 4px;
    border: 1px solid #dbdfe1;
    -webkit-box-shadow: 0 0 1px grey;
    box-shadow: 0 0 1px grey;
  }
}
#table-edit-variant {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#table-edit-variant td,
#table-edit-variant th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table-edit-variant tr:nth-child(even) {
  // background-color: #f2f2f2;
}

#table-edit-variant tr:hover {
  // background-color: #ddd;
}

#table-edit-variant th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ddd;
  color: black;
}
</style>
