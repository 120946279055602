<template>
  <div class="">
    <v-row v-if="!loading" style="max-width: 972px;height: calc(100% - 112px);">
      <img v-if="preview" width="100%" height="100%" :src="preview" alt="" srcset="" />
    </v-row>

    <div style="width: 100%; height: 100%" v-else class="d-flex justify-center align-center">
      <page-loader />
    </div>
  </div>
</template>

<script>
import { printHubApi } from '@/apis/printHub';

export default {
  props: ['colorDefault'],
  data() {
    return {
      preview: '',
      loading: false,
    };
  },
  watch: {
    colorDefault() {
      console.log(`this.colorDefault`, this.colorDefault);
      this.getPreview();
    },
  },
  methods: {
    async getPreview(option) {
      this.loading = true;
      try {
        let mockup = this.$parent.campaignDefault.mockup;
        if (this.$parent.campaignDefault.designs[0].imageSrc) {
          let convertData = {
            color: this.colorDefault,
            ...this.$parent.campaignDefault.designs[0],
          };
          convertData.layout = this.$parent.campaignDefault.designs[0].slot;
          delete convertData.slot;
          let res = await printHubApi.previewMockupV2(this.$parent.campaignDefault.templates[0], convertData);
          this.preview = res.data.src;

          console.log(`res`, res);
        }
      } catch (error) {
        console.log(`error`, error);
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getPreview();
  },
};
</script>

<style lang="scss" scoped></style>
